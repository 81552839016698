import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAsRYbteuv-dE0RV8sykXxGqrStkb2lhPc",
  authDomain: "chat-e308c.firebaseapp.com",
  projectId: "chat-e308c",
  storageBucket: "chat-e308c.appspot.com",
  messagingSenderId: "982074431071",
  appId: "1:982074431071:web:f735edd093788e3d352dea"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
